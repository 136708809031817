'use strict';

angular.module('kljDigitalLibraryApp')
    .controller('SupportCtrl', ["$scope", "$rootScope", "$timeout", "$state",
        "appConfig", "$stateParams", "SupportService", "ngToast", "AccountDetailsService",
        function($scope, $rootScope, $timeout, $state,
            appConfig, $stateParams, SupportService, ngToast, AccountDetailsService) {

            var init = function() {
                $rootScope.title = 'Support';
                $scope.feedback = {
                    full_name: undefined,
                    email: undefined,
                    phone_number: undefined,
                    comments: undefined
                };
                $scope.phoneValid = true;
                $scope.nameValid = true;
                $scope.emailValid = true;
                $scope.commentsValid = true;
                $scope.nameErr = '';
                $scope.emailErr = '';
                $scope.commentsErr = '';
               
                console.log("State.current", $state);

            }
            if($rootScope.offlineApp){
                $scope.offline = true;
            }
            else{

                $scope.online = true;
                 getOrganizations();
            }

            function getOrganizations(){
                AccountDetailsService.getList()
                    .then(function(data) {
                        $scope.organizations = data;
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            }

            angular.element(document).ready(function() {

                $('.wrapper-inner').css('height', $(window).height() - appConfig.HEADER_HEIGHT_1024 + "px");

            });


            $scope.validatePhone = function(phoneNo) {
                var phoneno = /^\d{10}$/;
                if (phoneNo.match(phoneno)) {
                    $scope.phoneValid = true;
                } else {
                    $scope.phoneValid = false;
                }
            }


            $scope.validateEmail = function(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (email.match(re)) {
                    $scope.emailValid = true;
                } else {
                    $scope.emailValid = false;
                    $scope.emailErr = "Not a valid email";
                }
            }


            $scope.validateName = function(name) {
                var re = /^[a-zA-Z\s]*$/;
                if (name.match(re)) {
                    $scope.nameValid = true;
                } else {
                    $scope.nameValid = false;
                    $scope.nameErr = "Not a valid name";
                }
            }

            $scope.create = function() {


                if (!$scope.feedback.full_name) {
                    $scope.nameValid = false;
                    $scope.nameErr = "Please enter your name";
                }

                if (!$scope.feedback.email) {
                    $scope.emailValid = false;
                    $scope.emailErr = "Please enter your email";
                }

                if (!$scope.feedback.comments) {
                    $scope.commentsValid = false
                    $scope.commentsErr = "Please enter your feedback";
                }

                if (!$scope.feedback.full_name || !$scope.feedback.email || !$scope.feedback.comments)
                    return;

                if ($scope.emailValid && $scope.phoneValid && $scope.nameValid) {
                    SupportService.createFeedback($scope.feedback)


                    .then(function(response) {
                        clearFeedback();
                        showSuccessMessage();
                        ngToast.success("Successfully submitted the Feedback.");
                    })

                    .catch(function(error) {
                        $scope.showMessage = false;
                        $scope.errorMessage = appConfig.FAILED_SERVER_MESSAGE;
                        ngToast.error("Internet Server Error.");
                    });
                }
            }

            // $scope.formBody = function() {
            //     // var body = {};
            //     // body.feedback = $scope.feedback;
            //     return $scope.feedback;
            // }

            var clearFeedback = function() {
                $scope.feedback = {
                    full_name: undefined,
                    email: undefined,
                    phone_number: undefined,
                    comments: undefined
                };
                $scope.phoneValid = true;
                $scope.nameValid = true;
                $scope.emailValid = true;
                $scope.commentsValid = true;
            }

            var showSuccessMessage = function() {
                $scope.successMessage = appConfig.CREATE_RECORD_SUCCESS_MESSAGE + " a feedback";
                $scope.showMessage = true;
                $timeout(removeMessage, 1000);
            }

            var removeMessage = function() {
                $scope.showMessage = false;
            }

            //Event Emiters: communication between controllers
            $scope.$emit('showSearchBox', true);

            init();

        }
    ]);